import React, { useEffect } from "react";
import { useCallback } from "react";
import { NavLink } from "react-router-dom";

import { PrimaryButton, SecondaryButton } from "~/components/common/buttons";
import { AddIcon, CloseIcon, FileExcelIcon } from "~/components/common/icons";
import { Pagination } from "~/components/common/pagination";
import { PrimaryPanel, SplitPanes } from "~/components/common/panels";
import {
  PrimaryHeading,
  SecondaryHeading,
  Title,
} from "~/components/common/text";
import { Toolbar } from "~/components/common/toolbars";
import { DocumentsError } from "~/components/Documents/DocumentsError";
import { yellow } from "~/styles/theme/color";

import { Document_BreadcrumbsFragment } from "./DocumentsAPI.generated";
import { DocumentsBreadcrumbs } from "./DocumentsBreadcrumbs";
import { DocumentsColumns } from "./DocumentsColumns";
import { DocumentsCreateFolderDialog } from "./DocumentsCreateFolderDialog";
import { DocumentsHistoryFilters } from "./DocumentsHistoryFilters";
import { DocumentsHistoryTable } from "./DocumentsHistoryTable";
import { DocumentsMenu } from "./DocumentsMenu";
import { DocumentsName } from "./DocumentsName";
import { DocumentsPermissionsDialog } from "./DocumentsPermissionsDialog";
import { DocumentsPreview } from "./DocumentsPreview";
import { DocumentsRemoveDialog } from "./DocumentsRemoveDialog";
import { DocumentsRenameFailedDialog } from "./DocumentsRenameFailedDialog";
import { DocumentsSearchBar } from "./DocumentsSearchBar";
import { DocumentsSidebar } from "./DocumentsSidebar";
import { DocumentsTable } from "./DocumentsTable";
import { DocumentsTreeMenu } from "./DocumentsTreeMenu";
import { DocumentsUploadNew } from "./DocumentsUpload/DocumentsUploadNew";
import { DocumentsViewSwitcher } from "./DocumentsViewSwitcher";
import { DocumentsZipDownloadsDialog } from "./DocumentsZipDownloadsDialog";
import { useDocuments } from "./useDocuments";

export interface DocumentsProps {
  basePath: string;
  treeId: string;
  isUsersAdmin: boolean;
  areLabelsEnabled: boolean;
  isDocumentsAdmin: boolean;
  canViewDocumentsHistory: boolean;
  onView?: () => void;
}

export const Documents = (props: DocumentsProps) => {
  const {
    basePath,
    treeId,
    isUsersAdmin,
    isDocumentsAdmin,
    areLabelsEnabled,
    canViewDocumentsHistory,
    onView = () => {},
  } = props;

  const {
    routing,
    downloads,
    links,
    search,
    historyFilters,
    historyFilterOptions,
    queries,
    current,
    track,
    searchActions,
    tableData,
    move,
    rename,
    remove,
    createFolder,
    permissions,
    zipDownloads,
  } = useDocuments(basePath, treeId, isDocumentsAdmin);

  useEffect(() => onView(), [onView]);
  useEffect(() => {
    if (
      current.document?.id &&
      (routing.view === "single" || routing.view === "columns")
    ) {
      current.isFile ? track.filePreview() : track.folderView();
    }
  }, [track, current.document?.id, current.isFile, routing.view]);

  /** Document detail menu for table rows and file column */
  const renderDocumentMenu = useCallback(
    (document: Document_BreadcrumbsFragment) => (
      <DocumentsMenu
        track={track}
        canEdit={current.tree?.canEdit ?? false}
        canEditPermissions={permissions.canEdit}
        canRename={current.tree?.canRename ?? false}
        view={routing.view}
        document={document}
        download={downloads.downloadFile}
        getLink={links.getLink}
        getFullLink={links.getFullLink}
        move={move.stageDocument}
        editPermissions={permissions.edit}
        rename={rename.stageDocument}
        remove={remove.stageDocument}
        zipDownload={zipDownloads.stageDocument}
      />
    ),
    [
      track,
      current.tree,
      permissions.canEdit,
      routing.view,
      downloads.downloadFile,
      links.getLink,
      links.getFullLink,
      move.stageDocument,
      permissions.edit,
      rename.stageDocument,
      remove.stageDocument,
      zipDownloads.stageDocument,
    ]
  );

  /** Interactive document name for table rows and file column */
  const renderDocumentName = useCallback(
    (document: Document_BreadcrumbsFragment) => (
      <DocumentsName
        track={track}
        document={document}
        showPath={search.showSearch || routing.view === "history"}
        highlight={search.highlight}
        rename={rename.confirm}
        renameLoading={rename.confirmLoading}
        renameVisible={rename.confirmVisible}
        cancel={rename.reset}
      />
    ),
    [
      track,
      search.showSearch,
      search.highlight,
      routing.view,
      rename.confirm,
      rename.confirmLoading,
      rename.confirmVisible,
      rename.reset,
    ]
  );

  /** Documents dialogs - shared by all views */
  const dialogs = (
    <>
      <DocumentsCreateFolderDialog track={track} {...createFolder} />
      <DocumentsRenameFailedDialog {...rename} />
      <DocumentsRemoveDialog track={track} {...remove} />
      <DocumentsPermissionsDialog
        track={track}
        {...permissions}
        permissionType={current.tree?.permissionsType}
      />
      <DocumentsZipDownloadsDialog
        track={track}
        {...zipDownloads}
        downloadFile={downloads.downloadFile}
      />
    </>
  );

  /** Breadcrumbs navigation - shared by all views */
  const breadcrumbs = (
    <DocumentsBreadcrumbs
      view={routing.view}
      showSearch={search.showSearch}
      document={current.document}
      getLink={links.getLink}
      error={current.error ?? queries.columns?.error?.message ?? null}
      basePath={basePath}
    />
  );

  /** Search bar */
  const searchBar = routing.view !== "upload" && (
    <DocumentsSearchBar
      track={track}
      document={current.folder}
      isLoading={search.searchPending || queries.searchDocs.loading}
      isRoot={routing.isRoot}
      searchAll={search.searchAll}
      searchTerm={search.searchTerm}
      setSearchTerm={searchActions.setSearchTerm}
      toggleSearchAll={searchActions.toggleSearchAll}
      clearSearch={searchActions.clearSearch}
    />
  );

  /** Upload subtitle */
  const uploadSubtitle = routing.view === "upload" && (
    <SecondaryHeading title="Drag files or click choose to start uploading">
      Drag files or click choose to start uploading
    </SecondaryHeading>
  );

  /** History filters */
  const historyDropdownFilters = routing.view === "history" && (
    <DocumentsHistoryFilters
      ancestorIds={historyFilters.ancestorIds}
      setAncestorIds={historyFilters.setAncestorIds}
      activityTypes={historyFilters.activityTypes}
      setActivityTypes={historyFilters.setActivityTypes}
      fileTypes={historyFilters.fileTypes}
      setFileTypes={historyFilters.setFileTypes}
      userGroupIds={historyFilters.userGroupIds}
      setUserGroupIds={historyFilters.setUserGroupIds}
      userGroupItems={historyFilterOptions.userGroupItems}
      ancestorItems={historyFilterOptions.ancestorItems}
      showUserGroupPicker={isUsersAdmin}
    />
  );

  /** History export */
  const changeLogButton = routing.view === "history" &&
    current.tree?.changeLogUrl && (
      <SecondaryButton
        as="a"
        target="_blank"
        href={`${current.tree.changeLogUrl}?${routing.queryParams.toString()}`}
        onClick={() => {
          track.exportChangeLog();
        }}
      >
        <FileExcelIcon />
        <span>Export index</span>
      </SecondaryButton>
    );

  /** Confirm move documents button */
  const moveButtons = move.confirmVisible && (
    <>
      <Title style={{ color: yellow }}>
        {move.stagedCount === 1
          ? "Move 1 item to..."
          : `Move ${move.stagedCount} items to...`}
      </Title>
      <SecondaryButton
        onClick={() => {
          track.newFolderClick();
          createFolder.open();
        }}
      >
        <AddIcon />
        <span>New folder</span>
      </SecondaryButton>
      <PrimaryButton
        onClick={() => {
          track.moveConfirm(move.stagedIds);
          move.confirm();
        }}
        disabled={move.confirmDisabled}
      >
        Move
      </PrimaryButton>
      <SecondaryButton
        onClick={() => {
          track.moveCancel();
          move.reset();
        }}
        disabled={move.confirmDisabled}
      >
        <CloseIcon />
      </SecondaryButton>
    </>
  );

  const showUploadButton =
    current.tree?.canEdit &&
    (current.tree.allowRootFiles || !routing.isRoot) &&
    !search.showSearch &&
    !move.confirmVisible &&
    ["single", "columns", "upload"].includes(routing.view);

  /** Upload button */
  const uploadButton = current.folder?.id && showUploadButton && (
    <SecondaryButton
      as={NavLink}
      to={links.getLink(current.folder.id, "upload")}
      isActive={() => routing.view === "upload"}
      onClick={() => {
        track.uploadClick();
      }}
    >
      <AddIcon />
      <span>Upload</span>
    </SecondaryButton>
  );

  /** View switcher (list/columns/history) */
  const viewSwitcher = (
    <DocumentsViewSwitcher
      track={track}
      canViewHistory={!move.confirmVisible && canViewDocumentsHistory}
      view={routing.view}
      rootId={routing.rootId}
      documentId={routing.documentId}
      folderId={current.folder?.id ?? routing.rootId}
      getLink={links.getLink}
    />
  );

  /** Menu for actions related to the whole tree / multiple documents */
  const treeMenu = routing.view !== "history" && !move.confirmVisible && (
    <DocumentsTreeMenu
      track={track}
      canEdit={current.tree?.canEdit ?? false}
      canViewAccessLog={current.tree?.canViewAccessLog ?? false}
      accessLogUrl={current.tree?.accessLogUrl ?? null}
      fileIndexUrl={current.tree?.fileIndexUrl ?? null}
      createFolder={createFolder.open}
      moveDisabled={move.stageSelectedDisabled}
      zipDownloadDisabled={zipDownloads.stageSelectedDisabled}
      move={move.stageSelected}
      removeDisabled={remove.stageSelectedDisabled}
      remove={remove.stageSelected}
      zipDownload={zipDownloads.stageSelected}
    />
  );

  /** Search & actions toolbar */
  const toolbar = !current.isPreview && (
    <Toolbar>
      {searchBar}
      {uploadSubtitle}
      {historyDropdownFilters}
      {changeLogButton}
      {moveButtons}
      {viewSwitcher}
      {uploadButton}
      {treeMenu}
    </Toolbar>
  );

  /** Sidebar for document preview */
  const sidebar = current.isPreview && (
    <DocumentsSidebar
      track={track}
      documentId={routing.documentId}
      prevId={current.prev?.id}
      nextId={current.next?.id}
      downloadFile={downloads.downloadFile}
      getLink={links.getLink}
      getFullLink={links.getFullLink}
      remove={remove.stageCurrent}
    />
  );

  /** Primary content for the current view */
  const content = (() => {
    if (
      current.error === "Document not found." ||
      queries.columns?.error?.message === "Document not found."
    )
      return <DocumentsError src="not-found" />;
    if (
      current.error === "Not authorized." ||
      queries.columns?.error?.message === "Not authorized."
    )
      return <DocumentsError src="no-permissions" />;
    if (routing.view === "single" && current.isFile)
      return (
        <DocumentsPreview
          key={current.document?.id}
          document={current.document}
        />
      );
    if (
      routing.view === "single" ||
      (routing.view === "columns" && search.showSearch)
    )
      return (
        <DocumentsTable
          {...tableData}
          permissionsType={current.tree?.permissionsType}
          isDocumentsAdmin={isDocumentsAdmin}
          areLabelsEnabled={areLabelsEnabled}
          disableLink={rename.confirmVisible}
          getLink={links.getLink}
          renderDocumentMenu={renderDocumentMenu}
          renderDocumentName={renderDocumentName}
        />
      );
    if (routing.view === "columns")
      return (
        <DocumentsColumns
          track={track}
          document={queries.columns.data?.document}
          getLink={links.getLink}
          renderDocumentMenu={renderDocumentMenu}
          renderDocumentName={renderDocumentName}
        />
      );
    if (routing.view === "upload" && current.tree && current.folder)
      return (
        <DocumentsUploadNew
          tree={current.tree}
          parent={current.folder}
          getLink={links.getLink}
        />
      );
    if (routing.view === "history")
      return (
        <>
          <DocumentsHistoryTable
            data={queries.history.data?.writeHistory?.activity}
            getLink={links.getLink}
            renderDocumentName={renderDocumentName}
            downloadFile={downloads.downloadFile}
            downloadLoading={downloads.loadingDocument}
          />
          <Pagination
            page={historyFilters.page}
            pageSize={historyFilters.pageSize}
            count={queries.history.data?.writeHistory?.count ?? 0}
            setPage={(page) => {
              track.historyPageChange(page);
              historyFilters.setPage(page);
            }}
          />
        </>
      );
    return null;
  })();

  // Render the overall documents layout
  return (
    <>
      <SplitPanes>
        <PrimaryPanel>
          <PrimaryHeading>{breadcrumbs}</PrimaryHeading>
          {toolbar}
          {content}
        </PrimaryPanel>
        {sidebar}
      </SplitPanes>
      {dialogs}
    </>
  );
};
