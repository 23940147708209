// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from './api/types.generated';

import { gql } from '@apollo/client';
export type FeatureFlagFragment = (
  { readonly __typename: 'OrgFeatureFlags' }
  & Pick<Types.OrgFeatureFlags, 'documentLabels'>
);

export type OrgFragmentFragment = (
  { readonly __typename: 'Organization' }
  & Pick<Types.Organization, 'id' | 'name' | 'slug' | 'permissions'>
  & { readonly featureFlags: (
    { readonly __typename: 'OrgFeatureFlags' }
    & FeatureFlagFragment
  ) }
);

export type NavbarViewerFragment = (
  { readonly __typename: 'User' }
  & Pick<Types.User, 'id' | 'firstName' | 'email' | 'avatarUrl' | 'hasSalesforceToken' | 'isSuperUser'>
  & { readonly organizations?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'Organization' }
    & OrgFragmentFragment
  )>>, readonly permissions?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'UserPermissions' }
    & Pick<Types.UserPermissions, 'name' | 'holdingCompanyIds' | 'portfolioIds'>
  )>> }
);

export const FeatureFlagFragmentDoc = gql`
    fragment FeatureFlag on OrgFeatureFlags {
  documentLabels
}
    `;
export const OrgFragmentFragmentDoc = gql`
    fragment OrgFragment on Organization {
  id
  name
  slug
  permissions
  featureFlags {
    ...FeatureFlag
  }
}
    ${FeatureFlagFragmentDoc}`;
export const NavbarViewerFragmentDoc = gql`
    fragment NavbarViewer on User {
  id
  firstName
  email
  avatarUrl
  hasSalesforceToken
  isSuperUser
  organizations {
    ...OrgFragment
  }
  permissions {
    name
    holdingCompanyIds
    portfolioIds
  }
}
    ${OrgFragmentFragmentDoc}`;