import React from "react";
import styled from "styled-components";

import { device } from "~/styles/mixins";

const LeftArrow = ({ className }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="16"
  >
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M3.947 8.001l5.6-5.437a1.476 1.476 0 000-2.125 1.58 1.58 0 00-2.187 0L.3 7.29a.995.995 0 000 1.422l7.061 6.85a1.582 1.582 0 002.187 0 1.476 1.476 0 000-2.124L3.948 8z"
    />
  </svg>
);

export const RightArrow = styled(LeftArrow)`
  transform: rotate(180deg);
  vertical-align: top;

  margin-top: 12px;
  @media ${device.xs} {
    margin-top: 16px;
  }
`;
