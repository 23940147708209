import { useDocumentsEventTracking } from "~/components/Documents/useDocumentsEventTracking";
import { useDocumentsHistoryFilterOptions } from "~/components/Documents/useDocumentsHistoryFilterOptions";
import { useDocumentsPermissions } from "~/components/Documents/useDocumentsPermissions";
import { useDocumentsZipDownloads } from "~/components/Documents/useDocumentsZipDownloads";

import { useDocumentsCreateFolder } from "./useDocumentsCreateFolder";
import { useDocumentsCurrent } from "./useDocumentsCurrent";
import { useDocumentsDownloads } from "./useDocumentsDownloads";
import { useDocumentsHistoryFilters } from "./useDocumentsHistoryFilters";
import { useDocumentsLinks } from "./useDocumentsLinks";
import { useDocumentsMove } from "./useDocumentsMove";
import { useDocumentsQueries } from "./useDocumentsQueries";
import { useDocumentsRemove } from "./useDocumentsRemove";
import { useDocumentsRename } from "./useDocumentsRename";
import { useDocumentsRouting } from "./useDocumentsRouting";
import { useDocumentsSearch } from "./useDocumentsSearch";
import { useDocumentsSearchActions } from "./useDocumentsSearchActions";
import { useDocumentsTableData } from "./useDocumentsTableData";

/**
 * Wires together all of the top level Documents hooks and returns their
 * results. This makes it easier to test the main Documents component by
 * mocking just this one hook
 */
export const useDocuments = (
  basePath: string,
  treeId: string,
  isDocumentsAdmin: boolean
) => {
  const routing = useDocumentsRouting(treeId);
  const links = useDocumentsLinks(basePath, routing);
  const search = useDocumentsSearch(routing);
  const historyFilters = useDocumentsHistoryFilters(routing);
  const queries = useDocumentsQueries(treeId, routing, search, historyFilters);
  const historyFilterOptions = useDocumentsHistoryFilterOptions(
    treeId,
    queries,
    isDocumentsAdmin
  );
  const current = useDocumentsCurrent(routing, queries);
  const track = useDocumentsEventTracking(routing, current);
  const downloads = useDocumentsDownloads(treeId, track);
  const searchActions = useDocumentsSearchActions(routing, links, current);
  const tableData = useDocumentsTableData(routing, search, queries);
  const move = useDocumentsMove(treeId, routing, tableData);
  const rename = useDocumentsRename(treeId, routing, search);
  const remove = useDocumentsRemove(treeId, routing, links, current, tableData);
  const createFolder = useDocumentsCreateFolder(treeId, current);
  const permissions = useDocumentsPermissions(treeId, current);
  const zipDownloads = useDocumentsZipDownloads(treeId, tableData);

  return {
    routing,
    downloads,
    links,
    search,
    historyFilters,
    historyFilterOptions,
    queries,
    current,
    track,
    searchActions,
    tableData,
    move,
    rename,
    remove,
    createFolder,
    permissions,
    zipDownloads,
  };
};
