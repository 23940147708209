import LoadingSpinner from "components/generic/LoadingSpinner";
import gql from "graphql-tag";
import React, { useEffect } from "react";

import ProjectDocsLink from "~/components/Projects/ProjectDocsLink";
import ProjectInfo from "~/components/Projects/ProjectInfo";
import ProjectLocation from "~/components/Projects/ProjectLocation";
import { ProjectDetailFragment } from "~/components/Projects/ProjectPage.generated";
import ProjectRadiusBar from "~/components/Projects/ProjectRadiusBar";
import { ProjectsMapFragment } from "~/components/Projects/ProjectsMap.generated";

export const PROJECT_DETAIL_FRAGMENT = gql`
  fragment ProjectDetail on Project {
    id
    name
    type
    status
    isOwned
    cod
    acquisitionDate
    ppaLength
    ppaRemainingAvg
    panelCount
    inverterCount
    offtakerName
    offtakerCredit
    moduleManufacturer
    inverterManufacturer
    acquiredPortfolio
    capacity
    capacityAc
    location {
      address
      city
      state
      zipCode
      elevation
      latitude
      longitude
    }
    system {
      system
      solarCapacityDc
      solarCapacityAc
      bessKw
      bessKwh
      installation
      mounting
    }
    utility {
      name
      zone
      iso
    }
    withinOne(
      portfolioIds: $portfolioIds
      portfolioStatuses: $portfolioStatuses
    ) {
      id
      name
    }
    withinTen(
      portfolioIds: $portfolioIds
      portfolioStatuses: $portfolioStatuses
    ) {
      id
      name
    }
    withinOneCount(
      portfolioIds: $portfolioIds
      portfolioStatuses: $portfolioStatuses
    )
    withinOneSumCapacityAc(
      portfolioIds: $portfolioIds
      portfolioStatuses: $portfolioStatuses
    )
    withinTenCount(
      portfolioIds: $portfolioIds
      portfolioStatuses: $portfolioStatuses
    )
    withinTenSumCapacityAc(
      portfolioIds: $portfolioIds
      portfolioStatuses: $portfolioStatuses
    )
  }
`;

export interface ProjectPageProps {
  project?: ProjectDetailFragment | null;
  projects?: ProjectsMapFragment["projects"];
  portfolioId?: string;
  loading: boolean;
  isProjectsAdmin: boolean;
  onView?: (project: ProjectDetailFragment) => void;
}

const ProjectPage = ({
  project,
  projects,
  portfolioId,
  loading,
  isProjectsAdmin,
  onView = () => {},
}: ProjectPageProps) => {
  useEffect(() => {
    if (project) {
      onView?.(project);
    }
  }, [onView, project]);

  if (loading) return <LoadingSpinner />;
  return (
    // TODO: pull breadcrumb styles out of documents
    <div className="documents">
      <ProjectInfo project={project} />
      <ProjectDocsLink project={project} portfolioId={portfolioId} />
      <ProjectRadiusBar project={project} />
      {project?.location.latitude && project?.location.longitude && (
        <ProjectLocation
          projects={projects}
          activeProject={project}
          loading={loading}
          canMoveMarkers={isProjectsAdmin}
        />
      )}
    </div>
  );
};

export default ProjectPage;
