import { gql } from "@apollo/client";
import classNames from "classnames";
import {
  FUND_ENTITY_OVERVIEW_FRAGMENT,
  OverviewPage,
} from "components/Overview/OverviewPage";
import { BetterAlert as Alert } from "components/profile/Alert";
import PortfolioProjects from "components/Projects/PortfolioProjects";
import QAndA from "components/QAndA";
import { getAdminContact } from "components/utils/portfolio";
import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import { trimChar } from "utils/text";

import { FundEntityType } from "~/api/types.generated";
import { ChecklistPage } from "~/components/Checklist/ChecklistPage";
import { ChecklistViewerFragment } from "~/components/Checklist/ChecklistPage.generated";
import { AnalyticsPage } from "~/components/DataroomAnalytics/AnalyticsPage";
import { DEAL_ROOM_CARD_FRAGMENT } from "~/components/DealRoom/DealRoomCard";
import { Documents } from "~/components/Documents/Documents";
import {
  PortfolioBodyFragment,
  useViewPortfolioMutation,
} from "~/components/Portfolio/PortfolioBody.generated";
import { Q_AND_A_SUBJECT_FRAGMENT } from "~/components/QAndA/QAndA";
import { QAndASubjectFragment } from "~/components/QAndA/QAndA.generated";
import { UserGroupMembersPage } from "~/components/UserGroupMembers/UserGroupMembersPage";
import { useEventTracking } from "~/event-tracking/EventTrackingProvider";

const ADMIN_ROUTES = ["deal-teams"];

const getRedirect = (
  checklistEnabled: boolean,
  hasOverviewPagePermission: boolean,
  hasDocumentsPermission: boolean
) => {
  if (hasOverviewPagePermission) return "/overview";
  if (hasDocumentsPermission) return `/storage/documents`;
  if (checklistEnabled) return "/checklist";
  return null;
};

export const PORTFOLIO_BODY_FRAGMENT = gql`
  fragment PortfolioBody on Portfolio {
    id
    name
    slug
    org {
      slug
    }
    hasChecklist
    enableReports
    enableQAndA
    enableProjects
    dealManager {
      fullName
      email
    }
    documentsTree {
      id
    }
    reportsTree {
      id
    }
    ...FundEntityOverview
    ...QAndASubject
  }

  ${FUND_ENTITY_OVERVIEW_FRAGMENT}
  ${Q_AND_A_SUBJECT_FRAGMENT}
`;

export const PORTFOLIO_VIEW_MUTATION_FRAGMENT = gql`
  mutation ViewPortfolio($portfolioId: ID!) {
    createRecentlyVisitedPortfolio(id: $portfolioId) {
      ...DealRoomCard
    }
    ${DEAL_ROOM_CARD_FRAGMENT}
  }
`;

interface PortfolioBodyProps {
  portfolio: PortfolioBodyFragment & QAndASubjectFragment;
  viewer: ChecklistViewerFragment;
  isUsersAdmin: boolean;
  isDocumentsAdmin: boolean;
  areLabelsEnabled: boolean;
  canViewDocumentsHistory: boolean;
  hasOverviewPagePermission: boolean;
  hasDocumentsPermission: boolean;
  isProjectsAdmin: boolean;
  isQAndAAdmin: boolean;
  canEditQuestions: boolean;
  canEditQuestionAnswers: boolean;
}

export const PortfolioBody = ({
  portfolio,
  viewer,
  isUsersAdmin,
  isDocumentsAdmin,
  areLabelsEnabled,
  canViewDocumentsHistory,
  hasOverviewPagePermission,
  hasDocumentsPermission,
  isProjectsAdmin,
  isQAndAAdmin,
  canEditQuestions,
  canEditQuestionAnswers,
}: PortfolioBodyProps) => {
  const location = useLocation();
  const checklistEnabled =
    DJ_CONST.ENABLE_CHECKLIST && !!portfolio.hasChecklist;
  const projectsEnabled = portfolio.enableProjects;
  const qAndAEnabled = portfolio.enableQAndA;

  const pathName = trimChar(location.pathname, "/");
  const basePath = location.pathname === "/";
  // When navigating to the basePath, permissions determine where we can redirect to:
  const redirectTo =
    basePath &&
    getRedirect(
      checklistEnabled,
      hasOverviewPagePermission,
      hasDocumentsPermission
    );
  const boxClasses = classNames({
    "box--white": !pathName.startsWith("storage"),
    "box--admin": ADMIN_ROUTES.includes(pathName),
  });

  // App tracking
  const [createViewEvent] = useViewPortfolioMutation();
  useEffect(() => {
    createViewEvent({
      variables: { portfolioId: portfolio.id },
      update: (cache, { data }) => {
        cache.modify({
          fields: {
            recentlyVisited(existingFundEntities = []) {
              const targetRef = cache.writeFragment({
                data: data?.createRecentlyVisitedPortfolio,
                fragment: DEAL_ROOM_CARD_FRAGMENT,
              });
              const filteredFundEntities = existingFundEntities.filter(
                (f: any) => f.__ref !== targetRef?.__ref
              );
              return [targetRef, ...filteredFundEntities.slice(0, 5)];
            },
          },
        });
      },
    });
  }, [portfolio.id, createViewEvent]);
  // Mixpanel tracking
  const track = useEventTracking("Portfolio", {
    overviewView: [
      "Viewed OverviewPage",
      (subpage) => ({
        "Subpage Title": subpage.title,
        "Subpage ID": subpage.id,
      }),
    ],
    projectsView: "Viewed Projects",
    projectsSearch: [
      "Searched Projects",
      (query) => ({ "Search Query": query }),
    ],
    projectView: [
      "Viewed Project",
      (project) => ({
        "Project ID": project.id,
        "Project Name": project.name,
      }),
    ],
    documentsView: "Viewed Documents",
    reportsView: "Viewed Reports",
    checklistView: "Viewed Checklist",
    qandaView: "Viewed Q&A",
  });

  // @ts-ignore
  return (
    <div className={boxClasses}>
      {basePath && !redirectTo && (
        <Alert
          message={`Please contact ${getAdminContact(
            portfolio.dealManager?.fullName,
            portfolio.dealManager?.email
          )} for access to ${portfolio.name}.`}
          classNames={["alert-warning"]}
        />
      )}
      <Switch>
        <Route
          path="/overview"
          render={() => (
            <OverviewPage
              subject={portfolio}
              onViewSubpage={track.overviewView}
            />
          )}
        />

        {portfolio.documentsTree && (
          <Route path="/storage/documents/:documentId?">
            <Documents
              basePath="/storage/documents"
              treeId={portfolio.documentsTree.id}
              isUsersAdmin={isUsersAdmin}
              isDocumentsAdmin={isDocumentsAdmin}
              areLabelsEnabled={areLabelsEnabled}
              onView={track.documentsView}
              canViewDocumentsHistory={canViewDocumentsHistory}
            />
          </Route>
        )}

        {portfolio.reportsTree && (
          <Route path="/storage/reports/:documentId?">
            <Documents
              basePath="/storage/reports"
              treeId={portfolio.reportsTree.id}
              isUsersAdmin={isUsersAdmin}
              isDocumentsAdmin={isDocumentsAdmin}
              areLabelsEnabled={areLabelsEnabled}
              canViewDocumentsHistory={canViewDocumentsHistory}
              onView={track.reportsView}
            />
          </Route>
        )}

        {projectsEnabled && (
          <Route
            path="/projects/:projectId"
            render={() => (
              <PortfolioProjects
                portfolioId={portfolio.id}
                portfolioSlug={portfolio.slug}
                orgSlug={portfolio.org.slug}
                onView={track.projectView}
                isProjectsAdmin={isProjectsAdmin}
              />
            )}
          />
        )}
        {projectsEnabled && (
          <Route
            path="/projects"
            render={() => (
              <PortfolioProjects
                portfolioId={portfolio.id}
                portfolioSlug={portfolio.slug}
                onView={track.projectsView}
                onSearchChange={track.projectsSearch}
                isProjectsAdmin={isProjectsAdmin}
                orgSlug={portfolio.org.slug}
              />
            )}
          />
        )}
        {checklistEnabled && (
          <Route
            path="/checklist"
            render={() => (
              <ChecklistPage
                portfolioId={portfolio.id}
                viewer={viewer}
                onView={track.checklistView}
              />
            )}
          />
        )}
        {qAndAEnabled && (
          <Route
            path="/q-and-a"
            render={() => (
              <QAndA
                subject={portfolio}
                onView={track.qandaView}
                isQAndAAdmin={isQAndAAdmin}
                canEditQuestions={canEditQuestions}
                canEditQuestionAnswers={canEditQuestionAnswers}
              />
            )}
          />
        )}
        {isUsersAdmin && (
          <Route
            path="/deal-teams"
            render={() => (
              <UserGroupMembersPage
                fundEntityId={portfolio?.id}
                fundEntityType={FundEntityType.Portfolio}
                isSuperUser={!!viewer.isSuperUser}
              />
            )}
          />
        )}
        {portfolio.documentsTree && isDocumentsAdmin && (
          <Route path="/documents-analytics">
            <AnalyticsPage treeId={portfolio.documentsTree.id} />
          </Route>
        )}
        {redirectTo && <Redirect to={redirectTo} />}
      </Switch>
    </div>
  );
};
