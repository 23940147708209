// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type AcquiredPortfoliosQueryVariables = Types.Exact<{
  orgSlug: Types.Scalars['String'];
}>;


export type AcquiredPortfoliosQuery = (
  { readonly __typename: 'Query' }
  & { readonly portfolios?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'Portfolio' }
    & Pick<Types.Portfolio, 'id' | 'name'>
  )>> }
);

export type ModuleModelsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ModuleModelsQuery = (
  { readonly __typename: 'Query' }
  & { readonly panelModels?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'PanelModel' }
    & Pick<Types.PanelModel, 'id' | 'modelNumber'>
    & { readonly manufacturer: (
      { readonly __typename: 'InverterManufacturer' }
      & Pick<Types.InverterManufacturer, 'name'>
    ) | (
      { readonly __typename: 'PanelManufacturer' }
      & Pick<Types.PanelManufacturer, 'name'>
    ) }
  )>>, readonly inverterModels?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'InverterModel' }
    & Pick<Types.InverterModel, 'id' | 'modelNumber'>
    & { readonly manufacturer: (
      { readonly __typename: 'InverterManufacturer' }
      & Pick<Types.InverterManufacturer, 'name'>
    ) | (
      { readonly __typename: 'PanelManufacturer' }
      & Pick<Types.PanelManufacturer, 'name'>
    ) }
  )>> }
);

export type OfftakersQueryVariables = Types.Exact<{
  orgSlug: Types.Scalars['String'];
}>;


export type OfftakersQuery = (
  { readonly __typename: 'Query' }
  & { readonly offtakers?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'Offtaker' }
    & Pick<Types.Offtaker, 'id' | 'name' | 'quality' | 'note'>
  )>> }
);

export type CreateProjectMutationVariables = Types.Exact<{
  orgSlug: Types.Scalars['String'];
  name: Types.Scalars['String'];
  status: Types.ProjectStatus;
  isOwned?: Types.Maybe<Types.Scalars['Boolean']>;
  commercialOperatingDate?: Types.Maybe<Types.Scalars['Date']>;
  permissionToOperateDate?: Types.Maybe<Types.Scalars['Date']>;
  acquisitionDate?: Types.Maybe<Types.Scalars['Date']>;
  capacity?: Types.Maybe<Types.Scalars['Int']>;
  capacityAc?: Types.Maybe<Types.Scalars['Int']>;
  location?: Types.Maybe<Types.LocationInput>;
  contact?: Types.Maybe<Types.ContactInput>;
  ppaContracts?: Types.Maybe<ReadonlyArray<Types.PpaContractInput> | Types.PpaContractInput>;
  panels?: Types.Maybe<ReadonlyArray<Types.PanelInput> | Types.PanelInput>;
  inverters?: Types.Maybe<ReadonlyArray<Types.InverterInput> | Types.InverterInput>;
  offtakers?: Types.Maybe<ReadonlyArray<Types.OfftakerInput> | Types.OfftakerInput>;
  portfolios?: Types.Maybe<ReadonlyArray<Types.PortfolioInput> | Types.PortfolioInput>;
}>;


export type CreateProjectMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createProject?: Types.Maybe<(
    { readonly __typename: 'Project' }
    & Pick<Types.Project, 'id'>
  )> }
);

export type UpdateProjectMutationVariables = Types.Exact<{
  orgSlug: Types.Scalars['String'];
  projectId: Types.Scalars['ID'];
  name?: Types.Maybe<Types.Scalars['String']>;
  status?: Types.Maybe<Types.ProjectStatus>;
  isOwned?: Types.Maybe<Types.Scalars['Boolean']>;
  commercialOperatingDate?: Types.Maybe<Types.Scalars['Date']>;
  permissionToOperateDate?: Types.Maybe<Types.Scalars['Date']>;
  acquisitionDate?: Types.Maybe<Types.Scalars['Date']>;
  capacity?: Types.Maybe<Types.Scalars['Int']>;
  capacityAc?: Types.Maybe<Types.Scalars['Int']>;
  location?: Types.Maybe<Types.LocationInput>;
  contact?: Types.Maybe<Types.ContactInput>;
  ppaContracts?: Types.Maybe<ReadonlyArray<Types.PpaContractInput> | Types.PpaContractInput>;
  panels?: Types.Maybe<ReadonlyArray<Types.PanelInput> | Types.PanelInput>;
  inverters?: Types.Maybe<ReadonlyArray<Types.InverterInput> | Types.InverterInput>;
  offtakers?: Types.Maybe<ReadonlyArray<Types.OfftakerInput> | Types.OfftakerInput>;
  portfolios?: Types.Maybe<ReadonlyArray<Types.PortfolioInput> | Types.PortfolioInput>;
}>;


export type UpdateProjectMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateProject?: Types.Maybe<(
    { readonly __typename: 'Project' }
    & Pick<Types.Project, 'id'>
  )> }
);

export type MoveProjectMutationVariables = Types.Exact<{
  projectId: Types.Scalars['ID'];
  latitude: Types.Scalars['Float'];
  longitude: Types.Scalars['Float'];
}>;


export type MoveProjectMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly moveProject?: Types.Maybe<(
    { readonly __typename: 'Project' }
    & Pick<Types.Project, 'id' | 'withinOneCount' | 'withinTenCount' | 'withinOneSumCapacityAc' | 'withinTenSumCapacityAc'>
    & { readonly location: (
      { readonly __typename: 'ProjectLocation' }
      & Pick<Types.ProjectLocation, 'latitude' | 'longitude'>
    ), readonly withinOne?: Types.Maybe<ReadonlyArray<(
      { readonly __typename: 'ProjectDict' }
      & Pick<Types.ProjectDict, 'id' | 'name'>
    )>>, readonly withinTen?: Types.Maybe<ReadonlyArray<(
      { readonly __typename: 'ProjectDict' }
      & Pick<Types.ProjectDict, 'id' | 'name'>
    )>> }
  )> }
);


export const AcquiredPortfoliosDocument = gql`
    query AcquiredPortfolios($orgSlug: String!) {
  portfolios(workflowStatus: 2, orgSlug: $orgSlug) {
    id
    name
  }
}
    `;

/**
 * __useAcquiredPortfoliosQuery__
 *
 * To run a query within a React component, call `useAcquiredPortfoliosQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcquiredPortfoliosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcquiredPortfoliosQuery({
 *   variables: {
 *      orgSlug: // value for 'orgSlug'
 *   },
 * });
 */
export function useAcquiredPortfoliosQuery(baseOptions: Apollo.QueryHookOptions<AcquiredPortfoliosQuery, AcquiredPortfoliosQueryVariables>) {
        return Apollo.useQuery<AcquiredPortfoliosQuery, AcquiredPortfoliosQueryVariables>(AcquiredPortfoliosDocument, baseOptions);
      }
export function useAcquiredPortfoliosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AcquiredPortfoliosQuery, AcquiredPortfoliosQueryVariables>) {
          return Apollo.useLazyQuery<AcquiredPortfoliosQuery, AcquiredPortfoliosQueryVariables>(AcquiredPortfoliosDocument, baseOptions);
        }
export type AcquiredPortfoliosQueryHookResult = ReturnType<typeof useAcquiredPortfoliosQuery>;
export type AcquiredPortfoliosLazyQueryHookResult = ReturnType<typeof useAcquiredPortfoliosLazyQuery>;
export type AcquiredPortfoliosQueryResult = Apollo.QueryResult<AcquiredPortfoliosQuery, AcquiredPortfoliosQueryVariables>;
export const ModuleModelsDocument = gql`
    query ModuleModels {
  panelModels {
    id
    modelNumber
    manufacturer {
      name
    }
  }
  inverterModels {
    id
    modelNumber
    manufacturer {
      name
    }
  }
}
    `;

/**
 * __useModuleModelsQuery__
 *
 * To run a query within a React component, call `useModuleModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useModuleModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModuleModelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useModuleModelsQuery(baseOptions?: Apollo.QueryHookOptions<ModuleModelsQuery, ModuleModelsQueryVariables>) {
        return Apollo.useQuery<ModuleModelsQuery, ModuleModelsQueryVariables>(ModuleModelsDocument, baseOptions);
      }
export function useModuleModelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModuleModelsQuery, ModuleModelsQueryVariables>) {
          return Apollo.useLazyQuery<ModuleModelsQuery, ModuleModelsQueryVariables>(ModuleModelsDocument, baseOptions);
        }
export type ModuleModelsQueryHookResult = ReturnType<typeof useModuleModelsQuery>;
export type ModuleModelsLazyQueryHookResult = ReturnType<typeof useModuleModelsLazyQuery>;
export type ModuleModelsQueryResult = Apollo.QueryResult<ModuleModelsQuery, ModuleModelsQueryVariables>;
export const OfftakersDocument = gql`
    query Offtakers($orgSlug: String!) {
  offtakers(orgSlug: $orgSlug) {
    id
    name
    quality
    note
  }
}
    `;

/**
 * __useOfftakersQuery__
 *
 * To run a query within a React component, call `useOfftakersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfftakersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfftakersQuery({
 *   variables: {
 *      orgSlug: // value for 'orgSlug'
 *   },
 * });
 */
export function useOfftakersQuery(baseOptions: Apollo.QueryHookOptions<OfftakersQuery, OfftakersQueryVariables>) {
        return Apollo.useQuery<OfftakersQuery, OfftakersQueryVariables>(OfftakersDocument, baseOptions);
      }
export function useOfftakersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OfftakersQuery, OfftakersQueryVariables>) {
          return Apollo.useLazyQuery<OfftakersQuery, OfftakersQueryVariables>(OfftakersDocument, baseOptions);
        }
export type OfftakersQueryHookResult = ReturnType<typeof useOfftakersQuery>;
export type OfftakersLazyQueryHookResult = ReturnType<typeof useOfftakersLazyQuery>;
export type OfftakersQueryResult = Apollo.QueryResult<OfftakersQuery, OfftakersQueryVariables>;
export const CreateProjectDocument = gql`
    mutation CreateProject($orgSlug: String!, $name: String!, $status: ProjectStatus!, $isOwned: Boolean, $commercialOperatingDate: Date, $permissionToOperateDate: Date, $acquisitionDate: Date, $capacity: Int, $capacityAc: Int, $location: LocationInput, $contact: ContactInput, $ppaContracts: [PPAContractInput!], $panels: [PanelInput!], $inverters: [InverterInput!], $offtakers: [OfftakerInput!], $portfolios: [PortfolioInput!]) {
  createProject(
    orgSlug: $orgSlug
    name: $name
    status: $status
    isOwned: $isOwned
    commercialOperatingDate: $commercialOperatingDate
    permissionToOperateDate: $permissionToOperateDate
    acquisitionDate: $acquisitionDate
    capacity: $capacity
    capacityAc: $capacityAc
    location: $location
    contact: $contact
    ppaContracts: $ppaContracts
    panels: $panels
    inverters: $inverters
    offtakers: $offtakers
    portfolios: $portfolios
  ) {
    id
  }
}
    `;
export type CreateProjectMutationFn = Apollo.MutationFunction<CreateProjectMutation, CreateProjectMutationVariables>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      orgSlug: // value for 'orgSlug'
 *      name: // value for 'name'
 *      status: // value for 'status'
 *      isOwned: // value for 'isOwned'
 *      commercialOperatingDate: // value for 'commercialOperatingDate'
 *      permissionToOperateDate: // value for 'permissionToOperateDate'
 *      acquisitionDate: // value for 'acquisitionDate'
 *      capacity: // value for 'capacity'
 *      capacityAc: // value for 'capacityAc'
 *      location: // value for 'location'
 *      contact: // value for 'contact'
 *      ppaContracts: // value for 'ppaContracts'
 *      panels: // value for 'panels'
 *      inverters: // value for 'inverters'
 *      offtakers: // value for 'offtakers'
 *      portfolios: // value for 'portfolios'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>) {
        return Apollo.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument, baseOptions);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<CreateProjectMutation, CreateProjectMutationVariables>;
export const UpdateProjectDocument = gql`
    mutation UpdateProject($orgSlug: String!, $projectId: ID!, $name: String, $status: ProjectStatus, $isOwned: Boolean, $commercialOperatingDate: Date, $permissionToOperateDate: Date, $acquisitionDate: Date, $capacity: Int, $capacityAc: Int, $location: LocationInput, $contact: ContactInput, $ppaContracts: [PPAContractInput!], $panels: [PanelInput!], $inverters: [InverterInput!], $offtakers: [OfftakerInput!], $portfolios: [PortfolioInput!]) {
  updateProject(
    orgSlug: $orgSlug
    projectId: $projectId
    name: $name
    status: $status
    isOwned: $isOwned
    commercialOperatingDate: $commercialOperatingDate
    permissionToOperateDate: $permissionToOperateDate
    acquisitionDate: $acquisitionDate
    capacity: $capacity
    capacityAc: $capacityAc
    location: $location
    contact: $contact
    ppaContracts: $ppaContracts
    panels: $panels
    inverters: $inverters
    offtakers: $offtakers
    portfolios: $portfolios
  ) {
    id
  }
}
    `;
export type UpdateProjectMutationFn = Apollo.MutationFunction<UpdateProjectMutation, UpdateProjectMutationVariables>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      orgSlug: // value for 'orgSlug'
 *      projectId: // value for 'projectId'
 *      name: // value for 'name'
 *      status: // value for 'status'
 *      isOwned: // value for 'isOwned'
 *      commercialOperatingDate: // value for 'commercialOperatingDate'
 *      permissionToOperateDate: // value for 'permissionToOperateDate'
 *      acquisitionDate: // value for 'acquisitionDate'
 *      capacity: // value for 'capacity'
 *      capacityAc: // value for 'capacityAc'
 *      location: // value for 'location'
 *      contact: // value for 'contact'
 *      ppaContracts: // value for 'ppaContracts'
 *      panels: // value for 'panels'
 *      inverters: // value for 'inverters'
 *      offtakers: // value for 'offtakers'
 *      portfolios: // value for 'portfolios'
 *   },
 * });
 */
export function useUpdateProjectMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>) {
        return Apollo.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument, baseOptions);
      }
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<UpdateProjectMutation, UpdateProjectMutationVariables>;
export const MoveProjectDocument = gql`
    mutation MoveProject($projectId: ID!, $latitude: Float!, $longitude: Float!) {
  moveProject(projectId: $projectId, latitude: $latitude, longitude: $longitude) {
    id
    location {
      latitude
      longitude
    }
    withinOne {
      id
      name
    }
    withinTen {
      id
      name
    }
    withinOneCount
    withinTenCount
    withinOneSumCapacityAc
    withinTenSumCapacityAc
  }
}
    `;
export type MoveProjectMutationFn = Apollo.MutationFunction<MoveProjectMutation, MoveProjectMutationVariables>;

/**
 * __useMoveProjectMutation__
 *
 * To run a mutation, you first call `useMoveProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveProjectMutation, { data, loading, error }] = useMoveProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *   },
 * });
 */
export function useMoveProjectMutation(baseOptions?: Apollo.MutationHookOptions<MoveProjectMutation, MoveProjectMutationVariables>) {
        return Apollo.useMutation<MoveProjectMutation, MoveProjectMutationVariables>(MoveProjectDocument, baseOptions);
      }
export type MoveProjectMutationHookResult = ReturnType<typeof useMoveProjectMutation>;
export type MoveProjectMutationResult = Apollo.MutationResult<MoveProjectMutation>;
export type MoveProjectMutationOptions = Apollo.BaseMutationOptions<MoveProjectMutation, MoveProjectMutationVariables>;