// Should be kept in sync with PermissionType/Permission model
export const Permissions = [
  {
    codename: "dealroom:view",
    shortDescription: "View Dealroom",
  },
  {
    codename: "overview:view",
    shortDescription: "View Overview",
  },
  {
    codename: "access_log:view",
    shortDescription: "View Access Log",
  },
  {
    codename: "documents:view",
    shortDescription: "View Documents",
  },
  {
    codename: "documents:edit",
    shortDescription: "Create/Modify/Delete Documents",
  },
  {
    codename: "documents_history:view",
    shortDescription: "View Documents History",
  },
  {
    codename: "o_and_m:view",
    shortDescription: "View O And M",
  },
  {
    codename: "o_and_m:admin",
    shortDescription: "Manage O And M",
  },
  {
    codename: "projects:view",
    shortDescription: "View Projects",
  },
  {
    codename: "projects:admin",
    shortDescription: "Manage Projects",
  },
  {
    codename: "questions:view",
    shortDescription: "View Questions",
  },
  {
    codename: "questions:edit",
    shortDescription: "Create/Modify/Delete Questions",
  },
  {
    codename: "question_answers:view",
    shortDescription: "View Question Answers",
  },
  {
    codename: "question_answers:edit",
    shortDescription: "Create/Modify/Delete Question Answers",
  },
  {
    codename: "reports:view",
    shortDescription: "View Reports",
  },
  {
    codename: "reports_history:view",
    shortDescription: "View Reports History",
  },
  {
    codename: "reports:edit",
    shortDescription: "Create/Modify/Delete Reports",
  },
  {
    codename: "documents:admin",
    shortDescription: "Manage Documents",
  },
  {
    codename: "reports:admin",
    shortDescription: "Manage Reports",
  },
  {
    codename: "checklists:admin",
    shortDescription: "Manage Checklists",
  },
  {
    codename: "users:admin",
    shortDescription: "Manage Users",
  },
  {
    codename: "org:admin",
    shortDescription: "Manage Organization",
  },
];
