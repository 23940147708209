// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
export type ProjectDetailFragment = (
  { readonly __typename: 'Project' }
  & Pick<Types.Project, 'id' | 'name' | 'type' | 'status' | 'isOwned' | 'cod' | 'acquisitionDate' | 'ppaLength' | 'ppaRemainingAvg' | 'panelCount' | 'inverterCount' | 'offtakerName' | 'offtakerCredit' | 'moduleManufacturer' | 'inverterManufacturer' | 'acquiredPortfolio' | 'capacity' | 'capacityAc' | 'withinOneCount' | 'withinOneSumCapacityAc' | 'withinTenCount' | 'withinTenSumCapacityAc'>
  & { readonly location: (
    { readonly __typename: 'ProjectLocation' }
    & Pick<Types.ProjectLocation, 'address' | 'city' | 'state' | 'zipCode' | 'elevation' | 'latitude' | 'longitude'>
  ), readonly system: (
    { readonly __typename: 'System' }
    & Pick<Types.System, 'system' | 'solarCapacityDc' | 'solarCapacityAc' | 'bessKw' | 'bessKwh' | 'installation' | 'mounting'>
  ), readonly utility?: Types.Maybe<(
    { readonly __typename: 'Utility' }
    & Pick<Types.Utility, 'name' | 'zone' | 'iso'>
  )>, readonly withinOne?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'ProjectDict' }
    & Pick<Types.ProjectDict, 'id' | 'name'>
  )>>, readonly withinTen?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'ProjectDict' }
    & Pick<Types.ProjectDict, 'id' | 'name'>
  )>> }
);

export const ProjectDetailFragmentDoc = gql`
    fragment ProjectDetail on Project {
  id
  name
  type
  status
  isOwned
  cod
  acquisitionDate
  ppaLength
  ppaRemainingAvg
  panelCount
  inverterCount
  offtakerName
  offtakerCredit
  moduleManufacturer
  inverterManufacturer
  acquiredPortfolio
  capacity
  capacityAc
  location {
    address
    city
    state
    zipCode
    elevation
    latitude
    longitude
  }
  system {
    system
    solarCapacityDc
    solarCapacityAc
    bessKw
    bessKwh
    installation
    mounting
  }
  utility {
    name
    zone
    iso
  }
  withinOne(portfolioIds: $portfolioIds, portfolioStatuses: $portfolioStatuses) {
    id
    name
  }
  withinTen(portfolioIds: $portfolioIds, portfolioStatuses: $portfolioStatuses) {
    id
    name
  }
  withinOneCount(
    portfolioIds: $portfolioIds
    portfolioStatuses: $portfolioStatuses
  )
  withinOneSumCapacityAc(
    portfolioIds: $portfolioIds
    portfolioStatuses: $portfolioStatuses
  )
  withinTenCount(
    portfolioIds: $portfolioIds
    portfolioStatuses: $portfolioStatuses
  )
  withinTenSumCapacityAc(
    portfolioIds: $portfolioIds
    portfolioStatuses: $portfolioStatuses
  )
}
    `;