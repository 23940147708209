import React from "react";
import styled from "styled-components";

import { UserInviteFragment } from "~/components/Admin/User/UsersTable.generated";
import {
  FlashButton,
  MenuButton,
  TertiaryButton,
} from "~/components/common/buttons";
import {
  EmailOutline,
  MoreIcon,
  PencilIcon,
  TrashIcon,
} from "~/components/common/icons";
import {
  MenuTooltipContainer,
  useMenuTooltip,
} from "~/components/common/tooltips";
import { gray100 } from "~/styles/theme/color";

export interface UserMenuProps {
  invite: UserInviteFragment;
  loading: boolean;
  resend(token: string): void;
  remove(invite: UserInviteFragment): void;
  update(invite: UserInviteFragment): void;
}

export const UserMenu = React.memo(function UserMenu(props: UserMenuProps) {
  const { resend, remove, update, invite, loading } = props;

  const {
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
    setControlledVisible,
  } = useMenuTooltip();

  return (
    <>
      <DropdownButton ref={setTriggerRef} children={<MoreIcon />} />
      {visible && (
        <MenuTooltipContainer ref={setTooltipRef} {...getTooltipProps()}>
          {invite.accepted && (
            <MenuButton onClick={() => update(invite)} disabled={loading}>
              <PencilIcon />
              <span>Edit</span>
            </MenuButton>
          )}
          {!invite.accepted && (
            <FlashButton
              onClick={(event) => {
                event.stopPropagation();
                resend(invite.token);
                setTimeout(() => setControlledVisible(false), 1000);
              }}
              icon={<EmailOutline />}
              label="Resend Invitation"
              message="Email sent!"
            />
          )}
          <MenuButton danger onClick={() => remove(invite)} disabled={loading}>
            <TrashIcon />
            <span>Delete</span>
          </MenuButton>
        </MenuTooltipContainer>
      )}
    </>
  );
});

const DropdownButton = styled(TertiaryButton)`
  background-color: ${gray100};
`;
