import gql from "graphql-tag";
import React, { useEffect } from "react";
import styled from "styled-components";

import { PermissionType } from "~/api/types.generated";
import { PageContainer } from "~/components/common/layout";
import {
  DEAL_ROOM_BANNER_FRAGMENT,
  DealRoomBanner,
} from "~/components/DealRoom/DealRoomBanner";
import EmptyState from "~/components/QAndA/components/EmptyState";
import { useEventTracking } from "~/event-tracking/EventTrackingProvider";
import { USER_ORG_FRAGMENT } from "~/Navbar";
import { device } from "~/styles/mixins";

import {
  HomePageViewerFragment,
  useHomePageLazyQuery,
} from "./HomePage.generated";
import {
  HOME_PAGE_PROJECTS_BANNER_FRAGMENT,
  ProjectsBanner,
} from "./ProjectsBanner";
import { RECENTLY_VISITED_FRAGMENT, RecentlyVisited } from "./RecentlyVisited";
import WelcomeBanner from "./WelcomeBanner";

export const HOME_PAGE_QUERY = gql`
  query HomePage($recentlyVisitedLimit: Int!, $orgSlug: String!) {
    ...HomePageProjectsBanner
    ...DealRoomBanner
    ...RecentlyVisited
  }

  ${HOME_PAGE_PROJECTS_BANNER_FRAGMENT}
  ${DEAL_ROOM_BANNER_FRAGMENT}
  ${RECENTLY_VISITED_FRAGMENT}
`;

export const HOME_PAGE_VIEWER_FRAGMENT = gql`
  fragment HomePageViewer on User {
    id
    fullName
    company {
      id
      name
      logoUrl
    }
    isSuperUser
    permissions {
      name
      holdingCompanyIds
      portfolioIds
    }
    organizations {
      id
      name
      slug
      permissions
      ...OrgFragment
    }
  }
  ${USER_ORG_FRAGMENT}
`;

export interface HomePageProps {
  emptyView: boolean;
  showAdmin: boolean;
  viewer?: HomePageViewerFragment;
}

export const HomePage = ({ emptyView, showAdmin, viewer }: HomePageProps) => {
  // TODO: This should be separated (Projects vs DealRoom) for the stats to work properly:
  const orgAdminPages = viewer?.organizations?.filter((org) =>
    org.permissions.includes(PermissionType.OrgAdmin)
  );
  const org = (orgAdminPages ?? [null])[0];
  const track = useEventTracking("Homepage", {
    view: "Viewed",
    projectsClick: "Clicked Projects",
    dealRoomClick: "Clicked Deal Room",
    recentlyVisitedClick: [
      "Clicked Recently Visited",
      (entity) => ({
        "Entity Type": entity.__typename,
        "Entity ID": entity.id,
        "Entity Name": entity.name,
      }),
    ],
  });

  useEffect(() => track.view(), [track]);

  const [getData, { data, loading }] = useHomePageLazyQuery({
    returnPartialData: true,
    pollInterval: 60000,
  });
  useEffect(() => {
    if (org?.slug)
      getData({
        variables: { orgSlug: org?.slug, recentlyVisitedLimit: 6 },
      });
  }, [org?.slug, getData]);

  return (
    <PageContainer>
      {viewer && (
        <WelcomeBanner
          userName={viewer.fullName ?? ""}
          company={viewer.company}
        />
      )}
      {!emptyView && (
        <>
          <BannerBox>
            {org && (
              <ProjectsBanner
                orgSlug={org.slug}
                data={data?.acquiredProjectsStats}
                onClickAnchor={track.projectsClick}
              />
            )}
            <DealRoomBanner
              anchor
              data={data}
              onClickAnchor={track.dealRoomClick}
              showAdmin={showAdmin}
            />
          </BannerBox>
          <RecentlyVisited
            loading={loading}
            entities={data?.recentlyVisited}
            onClickCard={track.recentlyVisitedClick}
          />
        </>
      )}
      {emptyView && <EmptyState text="No portfolios in diligence." />}
    </PageContainer>
  );
};

const BannerBox = styled.div`
  display: flex;
  align-items: stretch;
  align-content: space-around;
  margin: 1px 0;
  min-height: 152px;
  flex-direction: column;
  @media ${device.lg} {
    flex-direction: row;
  }
`;
