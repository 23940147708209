import classnames from "classnames";
import { formattedNumber } from "components/utils/portfolio";
import React from "react";

import { ProjectType } from "~/api/types.generated";
import { ProjectDetailFragment } from "~/components/Projects/ProjectPage.generated";

import ProjectInfoItem from "./ProjectInfoItem";

export interface ProjectInfoProps {
  project?: ProjectDetailFragment | null;
}

const ProjectInfo = ({ project }: ProjectInfoProps) => {
  if (project?.type === ProjectType.Solar) {
    return (
      <div className={classnames("project-section", "grid")}>
        <ProjectInfoItem header="SYSTEM SIZE (KW DC)">
          {project && formattedNumber(project.capacity)}
        </ProjectInfoItem>
        <ProjectInfoItem header="BESS (kW)">
          {project && formattedNumber(project.system?.bessKw)}
        </ProjectInfoItem>
        <ProjectInfoItem header="PROJECT TYPE">
          {project && project.type?.replace(/_/g, " ")}
        </ProjectInfoItem>

        {project && project.utility && (
          <ProjectInfoItem header="Utility">
            {project && project.utility?.name}
          </ProjectInfoItem>
        )}
        {project && !project.utility && (
          <ProjectInfoItem header="ACQUISITION DATE">
            {project && project.acquisitionDate}
          </ProjectInfoItem>
        )}
        <ProjectInfoItem header="OFFTAKER">
          {project && project.offtakerName}
        </ProjectInfoItem>
        <ProjectInfoItem header="SYSTEM SIZE (KW AC)">
          {project && formattedNumber(project.capacityAc)}
        </ProjectInfoItem>
        <ProjectInfoItem header="BESS (kWh)">
          {project && formattedNumber(project.system?.bessKwh)}
        </ProjectInfoItem>
        <ProjectInfoItem header="SYSTEM TYPE">
          {project &&
            (project.system?.system
              ? project.system?.system
              : project.system?.mounting)}
        </ProjectInfoItem>
        {project && project.utility && (
          <ProjectInfoItem header="ISO">
            {project && project.utility?.iso}
          </ProjectInfoItem>
        )}
        {project && !project.utility && (
          <ProjectInfoItem header="COD">
            {project && project.cod}
          </ProjectInfoItem>
        )}
        <ProjectInfoItem header="OFFTAKER CREDIT">
          {project && project.offtakerCredit}
        </ProjectInfoItem>
      </div>
    );
  }
  return (
    // TODO: Create lookups by project status for text display/class names. Create constants for states
    <div className={classnames("project-section", "grid")}>
      <ProjectInfoItem header="SYSTEM SIZE (KW DC)">
        {project && formattedNumber(project.capacity)}
      </ProjectInfoItem>
      <ProjectInfoItem header="BESS (kW)">
        {project && formattedNumber(project.system?.bessKw)}
      </ProjectInfoItem>
      <ProjectInfoItem header="PROJECT TYPE">
        {project && project.type?.replace(/_/g, " ")}
      </ProjectInfoItem>
      {project && project.utility && (
        <ProjectInfoItem header="Utility">
          {project && project.utility?.name}
        </ProjectInfoItem>
      )}
      {project && !project.utility && (
        <ProjectInfoItem header="ACQUISITION DATE">
          {project && project.acquisitionDate}
        </ProjectInfoItem>
      )}
      <ProjectInfoItem header="OFFTAKER">
        {project && project.offtakerName}
      </ProjectInfoItem>

      <ProjectInfoItem header="SYSTEM SIZE (KW AC)">
        {project && formattedNumber(project.capacityAc)}
      </ProjectInfoItem>
      <ProjectInfoItem header="BESS (kWh)">
        {project && formattedNumber(project.system?.bessKwh)}
      </ProjectInfoItem>
      <ProjectInfoItem header="SYSTEM TYPE">
        {project &&
          (project.system?.system
            ? project.system?.system
            : project.system?.mounting)}
      </ProjectInfoItem>
      {project && project.utility && (
        <ProjectInfoItem header="ISO">
          {project && project.utility?.iso}
        </ProjectInfoItem>
      )}
      {project && !project.utility && (
        <ProjectInfoItem header="COD">{project && project.cod}</ProjectInfoItem>
      )}
      <ProjectInfoItem header="OFFTAKER CREDIT">
        {project && project.offtakerCredit}
      </ProjectInfoItem>
    </div>
  );
};

export default ProjectInfo;
