import { gql } from "@apollo/client";
import {
  PORTFOLIO_BODY_FRAGMENT,
  PortfolioBody,
} from "components/Portfolio/PortfolioBody";
import {
  PORTFOLIO_HEADER_FRAGMENT,
  PortfolioHeader,
} from "components/Portfolio/PortfolioHeader";
import {
  PORTFOLIO_TABS_FRAGMENT,
  PortfolioTabs,
} from "components/Portfolio/PortfolioTabs";
import React, { useMemo } from "react";
import { BrowserRouter, useParams, useRouteMatch } from "react-router-dom";

import { PermissionType } from "~/api/types.generated";
import { ChecklistViewerFragment } from "~/components/Checklist/ChecklistPage.generated";
import { PageContainer } from "~/components/common/layout";
import LoadingSpinner from "~/components/generic/LoadingSpinner";
import { usePortfolioPageQuery } from "~/components/Portfolio/PortfolioPage.generated";
import { EventTrackingProvider } from "~/event-tracking/EventTrackingProvider";
import {
  OrgFeatureFlags,
  SubjectPermission,
} from "~/permissions/useViewerPermissions";

export const PORTFOLIO_PAGE_QUERY = gql`
  query PortfolioPage($slug: String!) {
    portfolio(slug: $slug) {
      ...PortfolioHeader
      ...PortfolioBody
    }
  }
  ${PORTFOLIO_HEADER_FRAGMENT}
  ${PORTFOLIO_BODY_FRAGMENT}
  ${PORTFOLIO_TABS_FRAGMENT}
`;

interface PortfolioPageProps {
  portfolioPermissions: SubjectPermission;
  viewer: ChecklistViewerFragment;
  featureFlagsByOrg: OrgFeatureFlags;
}

export const PortfolioPage = ({
  portfolioPermissions,
  viewer,
  featureFlagsByOrg,
}: PortfolioPageProps) => {
  const { slug } = useParams<{ slug: string }>();
  const match = useRouteMatch();
  const baseUrl = match.path.replace(":slug", slug);
  const { data, loading } = usePortfolioPageQuery({
    variables: { slug: slug },
  });
  const permissions = portfolioPermissions[data?.portfolio?.id ?? ""] ?? [];
  const showOverview = permissions.includes(PermissionType.OverviewView);
  const showDocuments = permissions.includes(PermissionType.DocumentsView);
  const hasProjectsPermission = permissions.includes(
    PermissionType.ProjectsView
  );
  const areLabelsEnabled = !!(
    featureFlagsByOrg[data?.portfolio?.org?.id || ""] ?? {}
  ).documentLabels;
  const hasQAndAPermission = permissions.includes(PermissionType.QuestionsView);
  const checklistsEnabled =
    (DJ_CONST.ENABLE_CHECKLIST && data?.portfolio?.hasChecklist) || false;
  const trackingContext = useMemo(
    () => ({
      "Portfolio Slug": slug,
      "Portfolio ID": data?.portfolio?.id,
      "Portfolio Name": data?.portfolio?.name,
    }),
    [slug, data?.portfolio?.id, data?.portfolio?.name]
  );

  if (loading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "2rem",
        }}
      >
        <LoadingSpinner big />
      </div>
    );
  return (
    <BrowserRouter basename={baseUrl}>
      <PageContainer>
        <EventTrackingProvider value={trackingContext}>
          {data?.portfolio && (
            <PortfolioHeader
              showAdminLink={
                !!viewer?.isSuperUser &&
                permissions.includes(PermissionType.OrgAdmin)
              }
              showUsersGroups={permissions.includes(PermissionType.UsersAdmin)}
              portfolio={data.portfolio}
            />
          )}
          {data?.portfolio && (
            <PortfolioTabs
              portfolio={data.portfolio}
              showDocuments={showDocuments}
              showOverview={showOverview}
              showProjects={
                hasProjectsPermission && data.portfolio.enableProjects
              }
              showQAndA={hasQAndAPermission && data.portfolio.enableQAndA}
              checklistsEnabled={checklistsEnabled}
            />
          )}
          {data?.portfolio && (
            <PortfolioBody
              portfolio={data.portfolio}
              viewer={viewer}
              isUsersAdmin={permissions.includes(PermissionType.UsersAdmin)}
              isDocumentsAdmin={permissions.includes(
                PermissionType.DocumentsAdmin
              )}
              areLabelsEnabled={areLabelsEnabled}
              canViewDocumentsHistory={permissions.includes(
                PermissionType.DocumentsHistoryView
              )}
              hasOverviewPagePermission={showOverview}
              hasDocumentsPermission={showDocuments}
              isProjectsAdmin={permissions.includes(
                PermissionType.ProjectsAdmin
              )}
              isQAndAAdmin={permissions.includes(PermissionType.OrgAdmin)}
              canEditQuestions={permissions.includes(
                PermissionType.QuestionsEdit
              )}
              canEditQuestionAnswers={permissions.includes(
                PermissionType.QuestionAnswersEdit
              )}
            />
          )}
        </EventTrackingProvider>
      </PageContainer>
    </BrowserRouter>
  );
};
