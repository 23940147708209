import gql from "graphql-tag";
import React, { useState } from "react";
import styled from "styled-components";

import BannerHeader from "~/components/Banners/BannerHeader";
import { RightArrow } from "~/components/HomePage/ArrowIcon";
import StatSection from "~/components/HomePage/StatSection";
import Stat from "~/components/typography/Stat";
import StatLabel from "~/components/typography/StatLabel";
import TitleAnchor from "~/components/typography/TitleAnchor";
import { formattedNumber } from "~/components/utils/portfolio";
import { bgColor, device } from "~/styles/mixins";
import { formatSize } from "~/utils/calculations";

import { DealRoomBannerFragment } from "./DealRoomBanner.generated";

export const DEAL_ROOM_BANNER_FRAGMENT = gql`
  fragment DealRoomBanner on Query {
    diligence: fundEntities(category: DILIGENCE) {
      count
      stats {
        projectCount
        maxSize
        minSize
        totalSize
      }
    }

    acquired: fundEntities(category: ACQUIRED) {
      count
    }

    market: fundEntities(category: MARKET) {
      count
    }
  }
`;

export interface DealRoomBannerProps {
  data?: DealRoomBannerFragment;
  anchor?: boolean;
  onClickAnchor?: () => void;
  showAdmin: boolean;
}

export const DealRoomBanner = ({
  data,
  anchor,
  onClickAnchor,
  showAdmin,
}: DealRoomBannerProps) => {
  const [collapsedHeader, setCollapsedHeader] = useState(false);
  return (
    <ContainerDiv>
      {anchor && (
        <TitleSection>
          <TitleAnchor to="/dealroom/diligence" onClick={onClickAnchor}>
            Deal Room <RightArrow />
          </TitleAnchor>
        </TitleSection>
      )}
      {!anchor && (
        <BannerHeader
          title="Deal Room"
          collapsed={collapsedHeader}
          setCollapsed={setCollapsedHeader}
          showAdmin={showAdmin}
        />
      )}
      <StatSection collapse={collapsedHeader}>
        <Block width={18} showXs showSmall showLarge>
          <StatLabel>Portfolios In Diligence</StatLabel>
          <Stat>{formattedNumber(data?.diligence?.count)}</Stat>
        </Block>
        <Block width={18} showSmall showLarge>
          <StatLabel>Diligence Total Size</StatLabel>
          <Stat>{formatSize(data?.diligence?.stats?.totalSize)}</Stat>
        </Block>
        <Block width={18} showLarge>
          <StatLabel>Diligence Total Projects</StatLabel>
          <Stat>{formattedNumber(data?.diligence?.stats?.projectCount)}</Stat>
        </Block>
      </StatSection>
    </ContainerDiv>
  );
};

const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  ${bgColor.gray800()};
  border-radius: 4px;
  flex-grow: 1;
  margin-bottom: 10px;
  padding: 0px 14px;
  @media ${device.xs} {
    padding: 5px 25px;
    margin-bottom: 1px;
  }
`;

const TitleSection = styled.div`
  display: flex;
  flex-grow: 1;
`;

const Block = styled.div<{
  width: number;
  showXs?: boolean;
  showSmall?: boolean;
  showLarge?: boolean;
}>`
  width: ${(props) => `${props.width}%`} !important;
  flex-grow: 1;
  font-weight: 200;
  display: ${(props) => (props.showXs ? "block" : "none")};
  @media ${device.sm} {
    display: ${(props) => (props.showSmall ? "block" : "none")};
  }
  @media ${device.lg} {
    display: ${(props) => (props.showLarge ? "block" : "none")};
  }
  @media ${device.xl} {
    display: block;
  }
`;
