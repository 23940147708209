import { LocationDescriptorObject } from "history";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import {
  CopyButton,
  MenuButton,
  SecondaryButton,
  TertiaryButton,
} from "~/components/common/buttons";
import {
  DownloadIcon,
  LockIcon,
  MoreIcon,
  MoveIcon,
  NewTabIcon,
  PencilIcon,
  TrashIcon,
} from "~/components/common/icons";
import {
  MenuTooltipContainer,
  useMenuTooltip,
} from "~/components/common/tooltips";
import { DocumentsEventTracking } from "~/components/Documents/useDocumentsEventTracking";

import { Document_BreadcrumbsFragment } from "./DocumentsAPI.generated";
import { View } from "./useDocumentsRouting";

export interface DocumentsMenuProps {
  track: DocumentsEventTracking;
  canEdit: boolean;
  canEditPermissions(): boolean;
  canRename: boolean;
  view: View;
  document: Document_BreadcrumbsFragment;
  download(id: string): void;
  getLink: (documentId: string) => LocationDescriptorObject;
  getFullLink: (documentId: string) => string;
  move(id: string): void;
  editPermissions(id: string): void;
  rename(id: string): void;
  remove(id: string): void;
  zipDownload(id: string): void;
}

export const DocumentsMenu = React.memo(function DocumentsMenu(
  props: DocumentsMenuProps
) {
  const {
    track,
    canEdit,
    canEditPermissions,
    canRename,
    view,
    document,
    download,
    getLink,
    getFullLink,
    move,
    editPermissions,
    rename,
    remove,
    zipDownload,
  } = props;

  const {
    getTooltipProps,
    setTooltipRef,
    setControlledVisible,
    setTriggerRef,
    visible,
  } = useMenuTooltip();

  useEffect(() => {
    if (visible) track.documentMenuOpen(document);
  }, [visible, track, document]);

  const Trigger = view === "columns" ? SecondaryButton : TertiaryButton;

  return (
    <>
      <Trigger ref={setTriggerRef} children={<MoreIcon />} />
      {visible && (
        <MenuTooltipContainer ref={setTooltipRef} {...getTooltipProps()}>
          {document.fileType === "File" && (
            <>
              <CopyButton
                text={getFullLink(document.id)}
                onClick={(event) => {
                  event.stopPropagation();
                  track.copyLinkClick(document);
                  setTimeout(() => setControlledVisible(false), 1000);
                }}
              />
              <MenuButton onClick={() => download(document.id)}>
                <DownloadIcon />
                <span>Download</span>
              </MenuButton>
              <MenuButton
                as={Link}
                to={getLink(document.id)}
                target="_blank"
                onClick={() => {
                  track.newTabClick(document);
                }}
              >
                <NewTabIcon />
                <span>Open in new tab</span>
              </MenuButton>
            </>
          )}

          {document.fileType === "Folder" && (
            <MenuButton
              onClick={() => {
                track.zipDownloadClick(document);
                zipDownload(document.id);
              }}
            >
              <DownloadIcon />
              <span>Download as Zip</span>
            </MenuButton>
          )}

          {canEditPermissions() && (
            <MenuButton
              onClick={() => {
                track.permissionsClick(document);
                editPermissions(document.id);
              }}
            >
              <LockIcon />
              <span>Permissions</span>
            </MenuButton>
          )}

          {view !== "columns" && canEdit && (
            <MenuButton
              onClick={() => {
                track.moveClick(document);
                move(document.id);
              }}
            >
              <MoveIcon />
              <span>Move</span>
            </MenuButton>
          )}

          {canRename && (
            <MenuButton
              onClick={() => {
                track.renameClick(document);
                rename(document.id);
              }}
            >
              <PencilIcon />
              <span>Rename</span>
            </MenuButton>
          )}

          {canEdit && (
            <MenuButton
              danger
              onClick={() => {
                track.deleteClick(document);
                remove(document.id);
              }}
            >
              <TrashIcon />
              <span>Delete</span>
            </MenuButton>
          )}
        </MenuTooltipContainer>
      )}
    </>
  );
});
