import React from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";

import { PermissionType, ProjectStatus } from "~/api/types.generated";
import ProjectForms, {
  ProjectValues,
} from "~/components/Projects/ProjectForms";
import { useCreateProjectMutation } from "~/components/Projects/ProjectsAPI.generated";
import { NotFound } from "~/pages/NotFound";
import { OrgPermission } from "~/permissions/useViewerPermissions";

interface NewProjectPageProps {
  permissionsByOrg: OrgPermission;
}

const NewProjectPage = ({ permissionsByOrg }: NewProjectPageProps) => {
  const { orgSlug } = useParams<{ orgSlug: string }>();
  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);
  const portfolioSlug = queryParams.get("portfolioSlug");
  const portfolioId = queryParams.get("portfolioId");
  let baseRedirect = `/${orgSlug}/projects`;
  if (portfolioSlug) baseRedirect = `/portfolios/${portfolioSlug}/projects`;
  const portfolioIds = [];
  if (portfolioId) {
    portfolioIds.push({ id: portfolioId });
  }
  const isProjectsAdmin = (permissionsByOrg[orgSlug || ""] ?? []).includes(
    PermissionType.ProjectsAdmin
  );

  const [createProject] = useCreateProjectMutation({
    onCompleted: (data) => {
      const projectId = data?.createProject?.id;
      if (projectId) {
        const url = `${baseRedirect}/${projectId}`;
        history.push(url);
      }
    },
  });

  const onSubmit = (values: ProjectValues) => {
    return createProject({ variables: { orgSlug: orgSlug, ...values } });
  };

  const initialValues: ProjectValues = {
    name: "",
    status: ProjectStatus.Operating,
    isOwned: false,
    commercialOperatingDate: null,
    permissionToOperateDate: null,
    acquisitionDate: null,
    capacity: undefined,
    capacityAc: undefined,
    location: {
      address: "",
      city: "",
      state: "",
      zipCode: "",
      latitude: undefined,
      longitude: undefined,
    },
    system: {},
    contact: {
      phoneNumber: "",
      balancingAuthorityAreaName: "",
    },
    panels: [],
    inverters: [],
    ppaContracts: [],
    offtakers: [],
    portfolios: portfolioIds,
  };

  if (!isProjectsAdmin) return <NotFound />;

  return (
    <ProjectForms
      initialValues={initialValues}
      orgSlug={orgSlug}
      allowPortfolioChanges={!portfolioId}
      onSubmit={onSubmit}
    />
  );
};

export default NewProjectPage;
