import numbro from "numbro";

/**
 * Sum objects by key.
 *
 * Goes through all the given objects and merges
 * them all by keys into one object with summed values.
 *
 * Example:
 * [{a: 3, b: 7}, {a: 2, b: 4}] => {a: 5: b: 11}
 */
export const mergeSum = (dataPoints) =>
  dataPoints.reduce((total, current) => {
    Object.keys(current).forEach((key) => {
      if (key !== "data") {
        total[key] = (total[key] || 0) + current[key]; // eslint-disable-line no-param-reassign
      }
    });
    return total;
  }, {});

export const formatThousands = (value) =>
  numbro(value || 0).format({ thousandSeparated: true, mantissa: 0 });

export const percentChange = (actual, forecast) =>
  forecast ? (actual / forecast - 1) * 100 : 0;

export const displayPercentChange = (value) =>
  `${Math.abs(value).toFixed(1)} %`;

const KWH = 1; // our chosen unit for power production, here for readability
const MWH = 1000 * KWH;
const GWH = 1000 * MWH;
const TWH = 1000 * GWH;

export const formatProduction = (productionKWh) => {
  if (!productionKWh) return "--";

  let units = "KWh";
  let precision = 4;
  let formatted = productionKWh;

  switch (true) {
    case productionKWh >= 100 * TWH:
      units = "TWh";
      formatted /= TWH;
      break;
    case productionKWh >= 100 * GWH:
      units = "GWh";
      formatted /= GWH;
      break;
    case productionKWh >= 100 * MWH:
      units = "MWh";
      formatted /= MWH;
      break;
    default:
      precision = 3;
  }

  formatted = numbro(formatted.toPrecision(precision)).format({
    thousandSeparated: true,
  });
  return `${formatted} ${units}`;
};

export const formatSizeComponents = (systemSizeKW) => {
  let units = "kW";
  let precision = 3;
  let formatted = systemSizeKW;

  if (systemSizeKW >= 100 * TWH) {
    units = "TW";
    formatted /= TWH;
    precision = 4;
  }
  if (systemSizeKW >= 100 * GWH) {
    units = "GW";
    formatted /= GWH;
    precision = 4;
  }
  if (systemSizeKW >= 100 * MWH) {
    units = "MW";
    formatted /= MWH;
    precision = 4;
  }
  formatted = numbro(formatted.toPrecision(precision)).format({
    thousandSeparated: true,
  });
  return { formatted, units };
};

// TODO: refactor with formatProduction
export const formatSize = (systemSizeKW, appendUnit = null) => {
  if (!systemSizeKW) return "--";
  const { formatted, units } = formatSizeComponents(systemSizeKW);
  if (appendUnit) {
    return `${formatted} ${units} ${appendUnit}`;
  }
  return `${formatted} ${units}`;
};

export function calculateSideData(aggregatedPoints, detailedView = false) {
  if (!aggregatedPoints) {
    return null;
  }
  const total = mergeSum(aggregatedPoints);

  // avoid division by zero if forecast data is missing
  let revVsForecast = total.forecast_rev
    ? (total.actual_rev / total.forecast_rev - 1) * 100
    : 0;
  let expVsForecast = total.forecast_exp
    ? (total.actual_exp / total.forecast_exp - 1) * 100
    : 0;
  let prodVsForecast = total.forecast_prod
    ? (total.actual_prod / total.forecast_prod - 1) * 100
    : 0;

  const revArrowDown = revVsForecast < 0;
  const expArrowDown = expVsForecast < 0;
  const prodArrowDown = prodVsForecast < 0;

  revVsForecast = Math.abs(revVsForecast).toFixed(1);
  expVsForecast = Math.abs(expVsForecast).toFixed(1);
  prodVsForecast = Math.abs(prodVsForecast).toFixed(1);

  // Watts conversion
  let production = 0;
  const actualProd = total.actual_prod || 0;

  if (detailedView) {
    production = formatProduction(actualProd);
  } else {
    production = `${formatThousands(actualProd / MWH)} MWh`;
  }

  return [
    {
      attribute: "Revenue YTD",
      value: `$${formatThousands(total.actual_rev)}`,
      vsForecast: `${revVsForecast} %`,
      green: !revArrowDown,
      isSelected: true,
      forecastDown: revArrowDown,
    },
    {
      attribute: "Expenses YTD",
      value: `$${formatThousands(total.actual_exp)}`,
      vsForecast: `${expVsForecast} %`,
      green: expArrowDown,
      isSelected: false,
      forecastDown: expArrowDown,
    },
    {
      attribute: "Production YTD",
      value: production,
      vsForecast: `${prodVsForecast} %`,
      green: !prodArrowDown,
      isSelected: false,
      forecastDown: prodArrowDown,
    },
  ];
}
