export enum WorkflowStatus {
  DRAFT = 1,
  ACQUIRED = 2,
  DILIGENCE = 4,
  MARKET = 6,
}

export enum WorkflowStatusDisplay {
  DRAFT = "Draft",
  ACQUIRED = "Acquired",
  DILIGENCE = "Diligence",
  MARKET = "Market",
}
