import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";

import { Toolbar } from "~/components/common/toolbars";
import OrderingContext, {
  useOrderingContext,
} from "~/components/Projects/context/OrderingContext";
import { EditProjectButton } from "~/components/Projects/EditProjectButton";
import { NewProjectButton } from "~/components/Projects/NewProjectButton";
import ProjectPage from "~/components/Projects/ProjectPage";
import { ProjectDetailFragment } from "~/components/Projects/ProjectPage.generated";
import { useProjectLazyQuery } from "~/components/Projects/ProjectsLayout.generated";
import ProjectsSearchBar from "~/components/Projects/ProjectsSearchBar";
import { useProjectsSearch } from "~/components/Projects/useProjectsSearch";
import { usePortfolioProjectsQuery } from "~/components/QAndA/api/queries.generated";

import ProjectsTable from "./ProjectsTable";

export interface PortfolioProjectsProps {
  portfolioId: string;
  portfolioSlug: string;
  isProjectsAdmin: boolean;
  onView?: (project?: ProjectDetailFragment) => void;
  onSearchChange?: (query: string) => void;
  orgSlug: string;
}

const PortfolioProjects = ({
  portfolioId,
  isProjectsAdmin,
  onView = () => {},
  onSearchChange,
  orgSlug,
  portfolioSlug,
}: PortfolioProjectsProps) => {
  const { projectId } = useParams<{ projectId: string }>();

  const isProjects = projectId === undefined;
  useEffect(() => {
    if (isProjects) onView();
  }, [onView, isProjects]);
  const search = useProjectsSearch();
  const ordering = useOrderingContext();

  const [loadProject, { data: projectDetail, loading: projectLoading }] =
    useProjectLazyQuery();

  useEffect(() => {
    if (projectDetail?.project) onView(projectDetail?.project);
  }, [onView, projectDetail]);

  useEffect(() => {
    if (projectId) {
      loadProject({
        variables: { projectId: projectId, portfolioIds: [portfolioId] },
      });
    }
  }, [projectId, portfolioId, loadProject]);

  const { data, previousData, loading } = usePortfolioProjectsQuery({
    variables: {
      portfolioId: portfolioId,
      search: search.debouncedSearchTerm,
      ordering: ordering.serializeOrdering(ordering.ordering),
    },
  });

  const projectTable = isProjects && (
    <ProjectsTable
      projects={data?.portfolio?.projects}
      previousProjects={previousData?.portfolio?.projects}
      loading={loading}
    />
  );

  const projectPage = !isProjects && (
    <ProjectPage
      project={projectDetail?.project}
      projects={data?.portfolio?.projects}
      portfolioId={portfolioId}
      loading={projectLoading}
      isProjectsAdmin={isProjectsAdmin}
    />
  );
  const renderBreadcrumbs = () => {
    return (
      <BreadcrumbContainer>
        <nav className="breadcrumb">
          <span className="breadcrumb-item">
            <Link to="/projects" className="d-inline-block text-primary">
              Projects
            </Link>
          </span>
          {projectDetail?.project && !isProjects && (
            <span className="breadcrumb-item">
              <span>{projectDetail?.project.name}</span>
            </span>
          )}
        </nav>
        {projectDetail?.project &&
          !isProjects &&
          projectId &&
          isProjectsAdmin && (
            <EditProjectButton
              orgSlug={orgSlug}
              projectId={projectId}
              portfolioSlug={portfolioSlug}
            />
          )}
      </BreadcrumbContainer>
    );
  };
  return (
    <OrderingContext.Provider value={ordering}>
      {/*TODO: pull breadcrumb styles out of documents*/}
      <div className="documents">
        {renderBreadcrumbs()}
        {isProjects && (
          <div className="d-flex align-items-start justify-content-between mb-2 mt-3">
            <Toolbar>
              <ProjectsSearchBar onSearchChange={onSearchChange} {...search} />
              {isProjectsAdmin && (
                <NewProjectButton
                  orgSlug={orgSlug}
                  portfolioId={portfolioId}
                  portfolioSlug={portfolioSlug}
                />
              )}
            </Toolbar>
          </div>
        )}
        {projectTable}
        {projectPage}
      </div>
    </OrderingContext.Provider>
  );
};

const BreadcrumbContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  min-height: 32px;
  justify-content: space-between;
`;

export default PortfolioProjects;
