// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../api/types.generated';

import { gql } from '@apollo/client';
export type UserInviteFragment = (
  { readonly __typename: 'OrgInvitation' }
  & Pick<Types.OrgInvitation, 'token' | 'firstName' | 'lastName' | 'email' | 'accepted' | 'groupId'>
  & { readonly existingUser?: Types.Maybe<(
    { readonly __typename: 'User' }
    & Pick<Types.User, 'firstName' | 'lastName' | 'companyName' | 'email'>
  )> }
);

export const UserInviteFragmentDoc = gql`
    fragment UserInvite on OrgInvitation {
  token
  firstName
  lastName
  email
  accepted
  groupId
  existingUser(orgSlug: $orgSlug) {
    firstName
    lastName
    companyName
    email
  }
}
    `;