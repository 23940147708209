import React, { useContext, useEffect, useRef } from "react";
import { animated, useTransition } from "react-spring";
import styled from "styled-components";

import { Dimensions } from "~/hooks/useDimensions";
import { bgColor, border, borderRadius } from "~/styles/mixins";
import { GetPropsWithoutRef } from "~/utils/types";

import DropdownContext from "./DropdownContext";
import DropdownTriggerContext from "./DropdownTriggerContext";

type ForwardedProps = GetPropsWithoutRef<typeof Window>;

export interface DropdownProps extends ForwardedProps {
  anchor?: DropdownSide;
  origin?: DropdownSide;
}

export type DropdownSide =
  | "top"
  | "right"
  | "bottom"
  | "left"
  | "center"
  | "top right"
  | "top left"
  | "bottom right"
  | "bottom left";

const Dropdown = ({
  children,
  anchor = "bottom left",
  origin = "top left",
  ...rest
}: DropdownProps) => {
  const triggerContext = useContext(DropdownTriggerContext);
  if (!triggerContext) {
    throw new Error(
      "Dropdown must be rendered within a dropdown trigger context."
    );
  }
  const { isOpen, triggerRect } = triggerContext;

  const transition = useTransition(isOpen, TRANSITION);

  const offsetterRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!offsetterRef.current) return;

    const node = offsetterRef.current;
    node.style.top = "0";
    const rect = node.getBoundingClientRect();

    if (rect.top < 0) {
      node.style.top = -rect.top + "px";
    } else if (rect.bottom > window.innerHeight) {
      node.style.top = window.innerHeight - rect.bottom + "px";
    }
  });

  return (
    <>
      {triggerRect &&
        transition(
          (props, item, transitionState, key) =>
            item && (
              <DropdownContext.Provider
                key={key}
                value={{ transitionState, ...triggerContext }}
              >
                <Positioner
                  style={{
                    pointerEvents: isOpen ? undefined : "none",
                    ...getPosition(triggerRect, anchor, origin),
                  }}
                >
                  <Offsetter ref={offsetterRef}>
                    <Transitioner style={{ ...props, transformOrigin: origin }}>
                      <Window {...rest}>{children}</Window>
                    </Transitioner>
                  </Offsetter>
                </Positioner>
              </DropdownContext.Provider>
            )
        )}
    </>
  );
};

const getAnchorPosition = (triggerRect: Dimensions, anchor: DropdownSide) => {
  const { top, right, bottom, left } = triggerRect;
  const centerX = (triggerRect.left + triggerRect.right) / 2;
  const centerY = (triggerRect.bottom + triggerRect.top) / 2;

  switch (anchor) {
    case "top":
      return { left: centerX, top: top };
    case "right":
      return { left: right, top: centerY };
    case "bottom":
      return { left: centerX, top: bottom };
    case "left":
      return { left: left, top: centerY };
    case "center":
      return { left: centerX, top: centerY };
    case "top right":
      return { left: right, top: top };
    case "top left":
      return { left: left, top: top };
    case "bottom right":
      return { left: right, top: bottom };
    case "bottom left":
      return { left: left, top: bottom };
  }
};

const getTranslate = (origin: DropdownSide) => {
  switch (origin) {
    case "top":
      return "translate(-50%, 0)";
    case "right":
      return "translate(-100%, -50%)";
    case "bottom":
      return "translate(-50%, -100%)";
    case "left":
      return "translate(0, -50%)";
    case "center":
      return "translate(-50%, -50%)";
    case "top right":
      return "translate(-100%, 0)";
    case "top left":
      return "translate(0, 0)";
    case "bottom right":
      return "translate(-100%, -100%)";
    case "bottom left":
      return "translate(0, -100%)";
  }
};

const getPosition = (
  triggerRect: Dimensions,
  anchor: DropdownSide,
  origin: DropdownSide
) => {
  let { left, top } = getAnchorPosition(triggerRect, anchor);

  if (anchor.includes("top") && origin.includes("bottom")) top -= 10;
  else if (anchor.includes("bottom") && origin.includes("top")) top += 10;

  if (anchor.includes("left") && origin.includes("right")) left -= 10;
  else if (anchor.includes("right") && origin.includes("left")) left += 10;

  return { top, left, transform: getTranslate(origin) };
};

const TRANSITION = {
  from: { opacity: 0, transform: "scale(0.9)" },
  enter: { opacity: 1, transform: "scale(1)" },
  leave: { opacity: 0, transform: "scale(0.9)" },
};

const Positioner = styled.div`
  position: fixed;
`;

const Offsetter = styled.div`
  position: relative;
`;

const Transitioner = styled(animated.div)``;

const Window = styled.div`
  ${borderRadius()};
  ${border.gray300()};
  ${bgColor.white()};
  position: relative;
  box-shadow: ${(props) => props.theme.common.boxShadow};

  & > :first-child,
  & > :first-child > :first-child,
  & > :first-child > :first-child > :first-child {
    ${borderRadius("top")};
  }

  & > :last-child,
  & > :last-child > :last-child,
  & > :last-child > :last-child > :last-child {
    ${borderRadius("bottom")};
  }
`;

export default Dropdown;
