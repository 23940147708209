// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
export type PortfolioHeaderFragment = (
  { readonly __typename: 'Portfolio' }
  & Pick<Types.Portfolio, 'id' | 'name' | 'slug' | 'status' | 'buildStatus' | 'systemSize' | 'projectCount' | 'averageOfftakerQuality' | 'remainingPpaTerm' | 'headerEnabled'>
  & { readonly operatingStats?: Types.Maybe<(
    { readonly __typename: 'OperatingStats' }
    & Pick<Types.OperatingStats, 'startDate' | 'actualExpenses' | 'actualProduction' | 'actualRevenue' | 'forecastExpenses' | 'forecastProduction' | 'forecastRevenue'>
  )>, readonly org: (
    { readonly __typename: 'Organization' }
    & Pick<Types.Organization, 'name'>
  ) }
);

export const PortfolioHeaderFragmentDoc = gql`
    fragment PortfolioHeader on Portfolio {
  id
  name
  slug
  status
  buildStatus
  systemSize
  projectCount
  averageOfftakerQuality
  remainingPpaTerm
  headerEnabled
  operatingStats {
    startDate
    actualExpenses
    actualProduction
    actualRevenue
    forecastExpenses
    forecastProduction
    forecastRevenue
  }
  org {
    name
  }
}
    `;