import { Grid, MenuItem } from "@material-ui/core";
import Dialog from "components/generic/Dialog";
import {
  FieldArray,
  Form,
  Formik,
  FormikHelpers as FormikActions,
  getIn,
} from "formik";
import gql from "graphql-tag";
import { trim } from "lodash";
import React, { EventHandler, useCallback, useEffect, useState } from "react";
import * as yup from "yup";

import {
  DeleteButton,
  InputRow,
  TableContainer,
  TableError,
  TableRowSelect,
  TableTitle,
  Tile,
} from "~/components/Admin/Group/UpdateGroupDialog";
import { OrgGroupFragment } from "~/components/Admin/User/NewUserDialog.generated";
import {
  useInvitationDetailLazyQuery,
  useUpdateUserGroupsMutation,
} from "~/components/Admin/User/UpdateUserDialog.generated";
import { UserInvitationsManagerProps } from "~/components/Admin/User/useUserInvitationsManager";
import { PrimaryButton, SecondaryButton } from "~/components/common/buttons";
import { AddIcon } from "~/components/common/icons";
import { StyledTextField } from "~/components/common/inputs";
import { BetterAlert as Alert } from "~/components/profile/Alert";

export const INVITATION_DETAIL_FRAGMENT = gql`
  fragment UserInvitationDetail on OrgInvitation {
    token
    groupIds(orgSlug: $orgSlug)
  }
`;

export const INVITATION_DETAIL_QUERY = gql`
  query InvitationDetail($orgSlug: String!, $token: ID!) {
    invitation(orgSlug: $orgSlug, token: $token) {
      ...UserInvitationDetail
    }
  }
  ${INVITATION_DETAIL_FRAGMENT}
`;

export const UPDATE_USER_GROUPS_MUTATION = gql`
  mutation UpdateUserGroups(
    $orgSlug: String!
    $invitationToken: ID!
    $groupIds: [ID!]
  ) {
    updateUserGroups(
      orgSlug: $orgSlug
      invitationToken: $invitationToken
      groupIds: $groupIds
    ) {
      ...UserInvitationDetail
    }
  }
  ${INVITATION_DETAIL_FRAGMENT}
`;

export const validationSchema = yup
  .object()
  .shape({
    groupIds: yup
      .array()
      .of(
        yup
          .number()
          .typeError("Please select a group.")
          .required("Please select a group.")
      ),
  })
  .required();
export type UpdateUserValues = yup.InferType<typeof validationSchema>;

export interface UpdateUserDialogProps extends UserInvitationsManagerProps {
  orgSlug: string;
  availableGroups: readonly OrgGroupFragment[];
}

export const UpdateUserDialog = (props: UpdateUserDialogProps) => {
  const { orgSlug, invitation, updateVisible, close, availableGroups } = props;
  const [alertMessage, setAlertMessage] = useState("");

  const [getData, { data }] = useInvitationDetailLazyQuery();
  useEffect(() => {
    if (invitation?.token)
      getData({
        variables: { orgSlug: orgSlug, token: invitation.token },
      });
  }, [orgSlug, invitation?.token, getData]);

  const initialValues: UpdateUserValues = {
    groupIds: data?.invitation?.groupIds ?? [],
  };
  const [update] = useUpdateUserGroupsMutation({
    onError: () =>
      setAlertMessage(
        "Unable to update user groups. Please contact support@dock.energy for assistance."
      ),
  });
  const handleSubmit = useCallback(
    async (
      formFields: UpdateUserValues,
      actions: FormikActions<UpdateUserValues>
    ) => {
      try {
        await update({
          variables: {
            orgSlug: orgSlug,
            invitationToken: invitation?.token,
            groupIds: formFields.groupIds,
          },
        });
        close();
        actions.resetForm({
          values: {
            groupIds: [],
          },
        });
      } catch (error) {
        actions.setFieldError("email", trim(error.message, "'"));
      } finally {
        actions.setSubmitting(false);
      }
    },
    [update, close, orgSlug, invitation?.token]
  );
  const submitButton = (disabled: boolean, submitForm: EventHandler<any>) => {
    return (
      <div>
        <PrimaryButton type="submit" disabled={disabled} onClick={submitForm}>
          Save
        </PrimaryButton>
      </div>
    );
  };
  return (
    <div>
      <Alert message={alertMessage} />
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          submitForm,
          isSubmitting,
          handleChange,
          errors,
          values,
          touched,
        }) => (
          <Dialog
            isOpen={updateVisible}
            onRequestClose={close}
            header={
              <span>
                <i
                  className="thumbnail icon icon--user"
                  style={{ width: "20px", height: "20px", marginBottom: "5px" }}
                />
                <span style={{ paddingLeft: "10px" }}>Update user groups</span>
              </span>
            }
            actions={submitButton(isSubmitting, submitForm)}
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item>
                <StyledTextField
                  fullWidth
                  disabled
                  label="Email"
                  defaultValue={`${invitation?.email}`}
                />
              </Grid>
              <Grid item>
                <Form>
                  <Tile>
                    <TableTitle>Group Access</TableTitle>
                    <TableContainer>
                      <FieldArray
                        name="groupIds"
                        render={(arrayHelpers) => (
                          <>
                            {values.groupIds.length > 0 &&
                              values.groupIds.map(
                                (groupId: string, index: number) => {
                                  const wasTouched =
                                    touched.groupIds &&
                                    Boolean(
                                      getIn(touched, `groupIds.${index}`)
                                    );
                                  const hasError =
                                    wasTouched &&
                                    Boolean(getIn(errors, `groupIds.${index}`));
                                  return (
                                    <InputRow
                                      key={`${index}-${groupId}`}
                                      error={hasError}
                                    >
                                      <div style={{ width: "90%" }}>
                                        <TableRowSelect
                                          fullWidth
                                          InputProps={{
                                            disableUnderline: true,
                                          }}
                                          defaultValue={groupId ?? ""}
                                          name={`groupIds.${index}`}
                                          variant="standard"
                                          select
                                          onChange={handleChange}
                                          error={hasError}
                                        >
                                          <MenuItem key="" value="">
                                            ---Select a group---
                                          </MenuItem>
                                          {availableGroups?.map(
                                            ({ id, name }) => (
                                              <MenuItem
                                                selected={groupId === id}
                                                key={`${name}`}
                                                value={id}
                                              >{`${name}`}</MenuItem>
                                            )
                                          )}
                                        </TableRowSelect>
                                      </div>
                                      <div
                                        style={{
                                          width: "10%",
                                          alignSelf: "center",
                                        }}
                                      >
                                        <DeleteButton
                                          type="button"
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        />
                                      </div>
                                    </InputRow>
                                  );
                                }
                              )}
                            <InputRow last>
                              <SecondaryButton
                                type="button"
                                style={{ width: "100%" }}
                                onClick={() => arrayHelpers.push("")}
                              >
                                <AddIcon />
                                <span>Add another group</span>
                              </SecondaryButton>
                            </InputRow>
                          </>
                        )}
                      />
                    </TableContainer>
                    {touched.groupIds && errors.groupIds && (
                      <TableError>Please select a group.</TableError>
                    )}
                  </Tile>
                </Form>
              </Grid>
            </Grid>
          </Dialog>
        )}
      </Formik>
    </div>
  );
};
